import "../public/stylesheets/Artisans.css";
import artisans from "../public/artisans.json";

export default function Artisans({ className }) {
  return (
    <div className={`container artisans-container font-face-tenor font-size-14 ${className}`}>
      <p className="description">Célébrons nos terroirs, notre art de vivre, célébrons nos artisans.<br />
        Dans cette rubrique, vous retrouverez des artisans venus d'ici et d'ailleurs.</p>

      <div className="grid-artisans">
        {artisans.map((artisan, index) => (
          <div className="conteneur-artisan">
            <img
              className="img-artisan"
              key={index}
              src={require(`../public/assets/artisans/${artisan.photo}`)}
              // src={artisan.photo}
              alt={artisan.alt}
            />
            <p className="artisanName artisan-card-text">{artisan.artisan}</p>
            <p className="artisanDescription artisan-card-text">{artisan.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
