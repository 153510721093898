import React, { useState } from "react";
import Select from 'react-select';
import "../public/stylesheets/components/ContactForm.scss";

// const FORM_ENDPOINT = "https://public.herotofu.com/v1/18c3be70-d607-11ed-a62e-917cebf1b719";
const FORM_ENDPOINT = "https://public.herotofu.com/v1/e0c280d0-d604-11ed-b656-837b57be60e0";

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            
            setSubmitted(true);
        }, 100);
    };

    const options = [
        { value: 'conseils', label: 'Des conseils ' },
        { value: 'renseignements', label: 'Des renseignements ' },
        { value: '3D sans mobilier', label: 'Un plan 3D de votre projet, sans mobilier' },
        { value: '3D avec mobilier', label: 'Un plan 3D de votre projet, avec mobilier' },
        { value: 'perspectives 3D', label: 'Des perspectives 3D photos réalistes pour vous projeter dans votre futur intérieur' },
        { value: 'ambiance projet', label: "Une planche d'ambiance de votre projet" },
        { value: 'matériaux', label: 'Une planche de matériaux' },
        { value: 'planche mobilier accesoires', label: "Une planche de mobilier et d'accessoires" },
        { value: 'planche shopping', label: 'Une planche shopping avec sélection de mobilier, accessoires, matériaux et couleurs' },
        { value: 'shopping list', label: 'Une shopping list détaillée avec les références, prix, quantité de chaque article ' },
        { value: '2D projet', label: 'Un plan 2D de votre projet' },
    ]

    if (submitted) {
        return (
            <>
                <div className="font-face-tenor">Merci,</div>
                <div className="font-face-tenor">Je vous recontacterai prochainement.</div>
            </>
        );
    }

    return (
        <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
            className="contact-form-container font-face-tenor w100"
            style={{ display: "flex", flexDirection: "column", alignItems: "start" }}
        >
            <p className="font-size-18 w100 header">contactez moi</p>
            <div className="contact-form-top-container">
                <div className="personnal-info-container">
                    <div className="form-field">
                        <label>Votre prénom</label>
                        <input
                            type="text"
                            name="Prénom"
                            className=""
                            required
                        />
                    </div>
                    <div className="form-field">
                        <label>Votre nom</label>
                        <input
                            type="text"
                            name="Nom"
                            className=""
                            required
                        />
                    </div>
                    <div className="form-field">
                        <label>Email</label>
                        <input
                            type="email"
                            name="Email"
                            className=""
                            required
                        />
                    </div>
                    <div className="form-field">
                        <label>Numéro de téléphone</label>
                        <input
                            type="telephone"
                            name="Numéro de téléphone"
                            className=""
                            required
                        />
                    </div>

                </div>
                <div className="prestations-container">
                    <label>Choisissez vos prestations</label>
                    <Select
                        isMulti
                        name="Prestations selectionées"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>

            </div>
            <div className="w100 mb24">
                <label className="">Besoin d'aide ou de conseils? Laissez moi un message!</label>
                <textarea
                    name="Message"
                    className="mt12"
                    required
                />
            </div>
            <div className="w100 text-align-center submit-button">
                <button
                    className="font-face-tenor font-size-14i    "
                    type="submit"
                >
                    Envoyer
                </button>
            </div>
        </form>
    );
};

export default ContactForm;