import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../public/stylesheets/APropos.css";
// import logo from "../public/assets/logos/logo_sans_fond_blanc.png";

import InspirationsSlider from "../components/InspirationsSlider";
const inspirations = require("../public/inspirations.json");

export default function APropos({ className }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  return (
    <div className={`container a-propos-container ${className}`}>
      <div className="mb48">
        <h1 className="font-face-ar">DÉCORATRICE D'INTÉRIEUR CERTIFIÉE</h1>
        <p className="font-face-tenor">
          Laissez entrer la lumière.<br />
          Jeux de transparences.<br />
          Reflet du soleil.<br />
          Minimalisme.<br />
          Tons ocres.<br />
          Matériaux naturels.<br />
          Intérieurs élégants.<br />
          Énergies positives.<br />
          Traversez les époques pour mieux les conjuguer.<br />
          Art de vivre.
        </p>
        <p className="font-face-tenor">Alchimie du décor.</p>
      </div>
      <div className="inspirations-container">
        <InspirationsSlider />
      </div>
    </div>
  );
}
