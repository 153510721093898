import "../public/stylesheets/Contact.css";
import logoNoir from "../public/assets/logos/logo_noir.png";
import ContactForm from "../components/ContactForm";

export default function Contact({ className }) {
  return (
    <div className={`container contact-container ${className}`}>
      <p className="font-face-tenor mt48 font-size-22">Secteur Comminges</p>
      <p className="font-face-tenor font-size-22 mb24">Haute-Garonne</p>
      <p className="font-face-tenor font-size-18">vinatier.maeva@yahoo.fr</p>
      <p className="font-face-tenor font-size-18 mb48">07.87.25.09.64</p>
      <ContactForm />
    </div>
  );
}
