import { useState, useEffect } from "react";
import Modal from "../components/Modal";
import "../public/stylesheets/Projects.css";
const projects = require("../public/projects.json");

export default function Projects({ className }) {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);
  const [imageDescription, setImageDescription] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleClick = (project, index) => {
    setCurrentIndex(index);
    setClickedImg(project.photo);
    !isMobile && setImageDescription(project.description);
    console.log(
      "image clicked!",
      index,
      clickedImg,
      "image description: ",
      imageDescription
    );
  };

  const handelRotationRight = () => {
    const totalLength = projects.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = projects[0].photo;
      const newDescription = projects[0].description;
      setClickedImg(newUrl);
      setImageDescription(newDescription);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = projects.filter((project) => {
      return projects.indexOf(project) === newIndex;
    });
    const newProject = newUrl[0].photo;
    const newDescription = newUrl[0].description;
    setClickedImg(newProject);
    setImageDescription(newDescription);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = projects.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = projects[totalLength - 1].photo;
      const newDescription = projects[totalLength - 1].description;
      setClickedImg(newUrl);
      setImageDescription(newDescription);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = projects.filter((project) => {
      return projects.indexOf(project) === newIndex;
    });
    const newProject = newUrl[0].photo;
    const newDescription = newUrl[0].description;
    setClickedImg(newProject);
    setImageDescription(newDescription);
    setCurrentIndex(newIndex);
  };

  return (
    <div className={`container projects-container ${className}`}>
      <p className="description font-face-tenor font-size-18">
        Travaux de perspectives 3D réalistes
      </p>
      <div className="grid-projects flex-grid">
        {projects.map((project, index) => (
          <div
            className="project-element text-align-center"
            // onClick={!isMobile ? () => handleClick(project, index) : null}
            onClick={() => handleClick(project, index)}
          >
            <img
              className="project-image"
              key={index}
              src={require(`../public/assets/projets/${project.photo}`)}
              //src={project.photo}
              alt={project.alt}
            />
            <span className="project-description">{project.description}</span>
          </div>
        ))}

        <div>
          {clickedImg && (
            // && !isMobile
            <Modal
              clickedImg={clickedImg}
              imageDescription={imageDescription}
              handelRotationRight={handelRotationRight}
              setClickedImg={setClickedImg}
              handelRotationLeft={handelRotationLeft}
            />
          )}
        </div>
      </div>
    </div>
  );
}
