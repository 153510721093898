import "./App.css";
import ResponsiveNav from "./components/ResponsiveNav";
import AppRouter from "./router/router";

function App() {
  return (
    <div className="App">
      <ResponsiveNav />
      <AppRouter />
    </div>
  );
}

export default App;
