import "../public/stylesheets/Home.css";
import logo_maeva_vinatier from '../public/assets/logos/logo_sans_fond_blanc.png';
import white_arrow_down from '../public/assets/home/down-arrow.png';
import projetImage from '../public/assets/projets/salon_minimaliste.png';
import { NavLink } from "react-router-dom";

export default function Home({ className }) {
  return (
    <div className={`container home-container text-align-center ${className}`}>

      <div className="logo-container">
        <img src={logo_maeva_vinatier} alt="logo maeva vinatier" className="logo-maeva-vinatier" />
        <p className="alchimie font-face-tenor">Alchimie du décor.</p>
        <img src={white_arrow_down} alt="fleche vers le bas" className="white_arrow_down" />
      </div>

      <div className="home-page-project-container">
        <p className="home-page-description-project font-face-tenor">Donnez vie à votre espace intérieur avec des plans 3D réalistes. Explorez mes réalisations passées dans la rubrique projet pour découvrir comment je peux vous aider à créer la décoration d’intérieur qui vous ressemble. </p>

        <div className="image-project">
          <NavLink to="/projects">
            <img
              key="salon_minimaliste"
              src={projetImage}
              alt="salon minimaliste découvrir mes projets"
            />
            <span className="font-face-ar">Découvrir mes projets</span>
          </NavLink>
        </div>

      </div>

    </div>
  );
}