import { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import close from "../public/assets/modal/close-w.png";
import menu from "../public/assets/navbar/menu-w.png";
import menu_brown from "../public/assets/navbar/menu-brown.png";
import logo from "../public/assets/logos/logo_sans_fond_blanc.png";
import "../public/stylesheets/components/ResponsiveNav.css";

function Navbar() {
  const [navbarClass, setNavbarClass] = useState("");

  const showNavbar = () => {
    setNavbarClass("responsive_nav")
  };

  const hideNavbar = () => {
    setNavbarClass("")
  };

  return (
    <header className="navbarBgBeige">
      <NavLink to="/" end>
        <img src={logo} alt="logo beige" className="logo-navbar hidden-on-desktop" />
      </NavLink>
      <img
        src={menu}
        alt="burger menu"
        className="nav-btn icon-navbar burger"
        onClick={showNavbar}
      />
      <nav className={`${navbarClass} sticky`}>
        <img
          src={close}
          alt="croix blanche"
          className="nav-btn nav-close-btn white-cross"
          onClick={hideNavbar}
        />
        <NavLink to="/" end onClick={hideNavbar}>
          Accueil
        </NavLink>
        <NavLink to="/projects" onClick={hideNavbar}>
          Projets
        </NavLink>
        <NavLink to="/a-propos" onClick={hideNavbar}>
          A propos
        </NavLink>
        <NavLink to="/artisans" onClick={hideNavbar}>
          Artisans
        </NavLink>
        <NavLink to="/contact" onClick={hideNavbar}>
          Contact
        </NavLink>
      </nav>
    </header>
  );
}

export default Navbar;
