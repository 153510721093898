import { Routes, Route, Navigate } from "react-router-dom";
import { Home, APropos, Artisans, Projects, Contact} from "../pages";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home  className="containerBgBeige"/>} />
      <Route path="/a-propos" element={<APropos className="containerBgBeige" />} />
      <Route path="/artisans" element={<Artisans className="containerBgBeige" />} />
      <Route path="/projects" element={<Projects  className="containerBgBeige" />} />
      <Route path="/contact" element={<Contact className="containerBgBeige" />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
    />
    </Routes>
  );
}
