import arrow_l from "../public/assets/modal/arrow-left-w.png";
import arrow_r from "../public/assets/modal/arrow-right-w.png";
import close from "../public/assets/modal/close-w.png";
import "../public/stylesheets/Modal.css";

const Modal = ({
  clickedImg,
  imageDescription,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          <span
            className="font-face-tenor"
            style={{ color: "white", width: "80%" }}
          >
            {imageDescription}
          </span>
          <img
            className="projectModal"
            src={require(`../public/assets/projets/${clickedImg}`)}
            alt="project"
          />
        </div>
        <img
          className="dismiss"
          onClick={handleClick}
          src={close}
          alt="fleche gauche"
        />
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
          <img src={arrow_l} alt="fleche gauche" />
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
          <img src={arrow_r} alt="fleche droite" />
        </div>
      </div>
    </>
  );
};

export default Modal;
