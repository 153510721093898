import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import inspirations from "../public/inspirations.json";
import "../public/stylesheets/components/InspirationsSlider.css";

export default function InspirationsSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="inspirations-slider-container text-align-center">
      <h2 className="font-face-ar">Mes inspirations</h2>
      <Slider {...settings}>
        {inspirations.map((inspiration, index) => (
          <div key={index}>
            <img
              className="inspiration-image"
              src={require(`../public/assets/a-propos/${inspiration.photo}`)}
              //src={inspiration.photo}
              alt={inspiration.alt}
            />
            <span className="font-face-tenor">
              {inspiration.description}
            </span>
          </div>
        ))}
      </Slider>
    </div>
  );
}
